.loginEtp{
  padding: 40px 0;
}
.etpPage{
  padding: 50px 20px;
  //width: 990px;
  width: 75%;
  //height: 100%;
  height: 360px;
  background: #E5EBF7;
  box-shadow: 0px 4px 6px rgba(0, 7, 20, 0.15);
}
.etpTop{
  span{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}
.etpBottom{
  margin-top: 30px;
  .line{
    border: 1px solid #7B808C;
    height: 100%;
    width: 2px;
    margin: 0 50px;
  }
  p{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
  span{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.TabOpen1{
  width: 100%;
  height: 192px;
  background: #FFFFFF;
  border-radius: 16px;
  .etp2{
    font-family: Inter;
    font-size: 14px;
    color: black;
    font-style: normal;
    font-weight: 600;
  }
}

.App{
  padding: 20px 0;
.drop{
  width: 470px;
  height: 48px;
  //background: red;
  border-radius: 8px;
  //&:focus{
  //  box-shadow: 0 0 0 0;
  //  background: #E5EBF7;
  //}
}
  .dropToggle{
    width: 470px;
    height: 48px;
    background: #E5EBF7;
    color: black;
    border: 0;
    margin-top: 15px;
    .toggleImg{
      width: 12px;
      height: 6px;
    }
    span{
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
    &:hover{
      background: rgb(216, 220, 233);
    }
    &:focus{
      box-shadow: 0 0 0 0;
      //background: #E5EBF7;
    }
  }
  .dropMenu{
    .dropItem{
      b{
        font-family: Inter;
        font-size: 13px;
      }
      .textB{
        margin-left: 5px;
      }
      span{
        font-family: Inter;
        font-style: normal;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
      }
      &:focus{
        background:#E5EBF7;
      }
    }
  }
  .signEtp{
    width: 122px;
    height: 40px;
    margin-top: 30px;
    background: #0166FF;
    border-radius: 8px;
    color: white;
    border: 0;
    text-align: center;
    .openLock{
      margin-right: 10px;
      width: 22px;
      height: 20px;
    }
    .imzolash{
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
