//Fonts sytle
//@font-face {
//  font-family: "Inter-Bold";
//  src: url("./public/font/Inter/Inter-Bold.ttf") format("truetype");
//}
//@font-face {
//  font-family: "Inter-Regular";
//  src: url("./public/font/Inter/Inter-Regular.ttf") format("truetype");
//}
//@font-face {
//  font-family: "Inter-Light";
//  src: url("./public/font/Inter/Inter-Light.ttf") format("truetype");
//}
//@font-face {
//  font-family: "Inter-SemiBold";
//  src: url("./public/font/Inter/Inter-SemiBold.ttf") format("truetype");
//}
$color-text: #000714;
$color-back: #969696;
.navbarTop{
}
.navbar1 {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #E5EBF7;
}

.logo {
  //height: 50px;
  //width: 54px;
}

.logoText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000714;
  margin-left: 10px;
}

.logoTextA {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 7, 20, 0.7);
  margin-left: 10px;
}

.dNone2 {
  display: none;
}

.dNone3 {
  //z-index: -5;
  display: none!important;
}
.imgFrom {
  position: absolute;
  left: 23%;
  z-index: 1;
  //background-color: white;
  //background-color: black;
  padding: 0px 10px;
  width: 45%;
  height: 100%;
  //display: none;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  //.dNone {
  //  display: none;
  //}
  .avform {
    //z-index: 5;
    width: 100%;
    height: auto;
    left: 0;
    margin-top: 15px;
    box-shadow: 0 0 0 0;
    .avform2 {
      border: 0;
      box-shadow: 0 0 0 0;
      width: 100%;
      placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.navbarTopRight {
  //position: relative;


  width: 65%;
  justify-content: end;

  img {
    margin-right: 18px;
  }

  span {
    margin-right: 18px;
  }
}

.icons {
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.changeColorIcon {
  transition: all 0.1s linear;
  &:active {
    transform: scale(1.3);
    //transform: rotateX(90deg);
  }
}

.eri {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0166FF;
}

.languageSelect {
  img {
    margin: 0;
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  select {
    border: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #201918;

    &:focus {
      outline: none;
    }

    option {
      border: 0;
      outline: none;
    }
  }
}

.navbarNav {
  margin-top: 12px;
  background: #FFFFFF;
  //background-color: red;
  box-shadow: 0px 4px 4px rgba(0, 7, 20, 0.15);
  padding-bottom: 15px;
  //margin-bottom: 15px;

  position: relative;

  .aLink {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 3px;
      //width: 0;
      background-color: black;
      margin-top: 40px;
      left: 0;
      right: 0;
      transition: all 0.3s ease-in-out;
      transform: scaleX(0);
    }

    &:hover {
      &:before {
        width: 100%;
        transform: scaleX(1);
      }
    }
  }
}

.navbarNavLeft {
  //position: relative;
  display: flex;
  align-items: center;
  .aLink {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  a:first-child {
    margin-left: 0;
  }

  a {
    color: black;
    margin-left: 15px;
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }


  .korxona {
    font-family: Inter;
    color: black;
    font-weight: bold;
    background-color: white;
    //position: relative;
    &:before {
      content: "";
      position: absolute;
      margin-left: 0;
      margin-top: 20px;
      margin-top: 40px;
      width: 128px;
      //width: 100%;
      background-color: black;
      transition: all 0.5s linear;
    }
    &.active {
      &:before {
        height: 3px;
      }
    }
  }
  .active {
    .korxonaLink {
      top: 43px;
      //top: -360px;
    }
  }
  .korxonaLink {
    padding-top: 15px;
    padding-bottom: 45px;
    transition: all 0.3s linear;
    position: absolute;
    z-index: 1;
    opacity: revert;
    top: -385px;
    width: 100%;
    //bottom: -255px;
    right: 0;
    //display: none;
    left: 0;
    background-color: white;
    height: 250px;
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
    //transition: all 1s linear;
    .links {
      position: relative;
      padding: 8px 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -15px;
        //bottom: 0;
        width: 4px;
        //height: 0;
        background-color: black;
        transition: all 0.2s linear;
        transform: scaleY(0);
      }

      &:hover {
        .tabLink {
          margin-left: 5px;
        }

        &:before {
          height: 90%;
          transform: scaleY(1);
        }
      }
    }
  }

  .korxona2 {
    font-family: Inter;
    color: black;
    font-weight: bold;
    margin-left: 15px;
    &:before {
      content: "";
      position: absolute;
      margin-left: 0;
      margin-top: 20px;
      margin-top: 40px;
      width: 153px;
      background-color: black;
      transition: all 0.5s linear;
    }

    &.active2 {
      &:before {
        height: 3px;
      }
    }
  }

  .active2 {
    .korxonaLink2 {
      top: 43px;
    }
  }


  .korxonaLink2 {
    padding-top: 15px;
    padding-bottom: 45px;
    transition: all 0.3s linear;
    position: absolute;
    z-index: 1;
    left: 0;
    top: -355px;
    //display: none;
    width: 100%;
    background-color: white;
    height: 248px;
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);

    //.links {
    //  padding: 8px 0;
    //}
    .links {
      position: relative;
      padding: 8px 0;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -15px;
        //bottom: 0;
        width: 4px;
        //height: 0;
        background-color: black;
        transition: all 0.2s linear;
        transform: scaleY(0);
      }

      &:hover {
        .tabLink {
          margin-left: 5px;
        }
        &:before {
          height: 90%;
          transform: scaleY(1);
        }
      }
    }
  }


}

.navbarNavRight {
  img {
    margin-right: 15px;
    z-index: 0;
  }

  .aLink {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: black;
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }
}

.footer {
  background-color: #001009;
  padding-top: 60px;
  padding-bottom: 40px;

  .uzbRes {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #424752;

    a {
      //target: "_blank";
    }
  }
  .myCarea{
    color: #7B808C;
    a{
      //color: #7B808C;
      &:hover{
      text-decoration: none;
    }
    }
  }
}

.footerLogo {
  //width: 126px;
  //height: 98px;
}

.footerLink {
  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.footerLink2 {
  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.footerMenu {
  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.footerDiv {
  margin-top: 10px;
  text-decoration: none;
}

.mesenger {
  img:first-child {
    padding-left: 0;
  }

  img {
    margin-top: 10px;
    padding: 0 10px;
    width: 45px;
    height: 35px;
  }
}

.footerLine {
  width: 100%;
  border: 1px solid #808784;
  //background-color: #808784;
  //padding: 10px 0;
  margin-top: 30px;
}

.section1 {
  padding: 50px 0;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    color: #000714;
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }

  img {
    width: inherit;

  }
}

.section2 {
  background-color: #E5EBF7;
  padding-top: 40px;
  padding-bottom: 40px;

  p {
    margin-top: 150px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  button {
    color: #0166FF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid #0166FF;
    margin-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: #0153a8;
      border: 1px solid #0153a8;
    }
  }
}

.section3 {
  //background-image: url("./public/images/Rectangle 50.png");
  height: 500px;
  .NewIMG {
    width: 100%;
    height: 100vh;
  }

  img {
    width: 100%;
  }

  .img2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    height: 120%;
  }

  position: relative;
  margin-top: 1px;
}

.section3Left {
  width: 45%;
  //height: 100%;
  //background-color: #0153a8;
  //background-color: #E5EBF7;
  position: absolute;
  top: 0;
  margin-top: 50px;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .textBlue {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0166FF;
    text-decoration: none;
    //cursor: revert;
  }
.batafsil{
  max-height: 75px;
  overflow: hidden;
  margin-top: 10px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
  .batafsil1{
    max-height: 160px;
    overflow: hidden;
  }
  .batafsilNew{
    margin-top: 40px;
    a{
      border: 2px solid #0166FF;
      color: #0166FF;
      border-radius: 5px;
      padding: 5px 30px;
      font-family: Inter;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }

  }
}



.section4 {
  background-color: white;
  text-align: center;
  padding: 50px 0;

  .col-6 {
    .card {
      text-align: center;
      background-color: #E5EBF7;
      border-radius: 64px;
      padding: 40px 20px;
      //height: 600px;
      h2 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 48px;
        margin-top: 40px;
      }

      p {
        margin-top: 15px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #424752;
        //padding-bottom: 30px;
        //margin-bottom: 20px;
        height: 30px;
      }

      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
      }

      a {
        margin-top: 50px;
        //margin-bottom: 30px;
        background-color: #0166FF;
        color: white;
        width: 58%;
        display: flex;
        align-items: center;
        padding: 12px 40px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;

        .img {
          width: 30px;
          height: 26px;
          //margin-left: 10px;
        }
      }
    }
  }
}

.section5 {
  padding: 72px 0;

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
  }

  h4 {
    margin-top: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #424752;
  }
}

.enterpriseOne {
  //accardion
  .acbacround {
    background: #E5EBF7;
    padding: 10px 0;
    box-shadow: 0 0 0 0;
    border: 0;

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    a {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }

    .lineac {
      border: 1px solid #7B808C;
      width: 100%;
      margin: 15px 0;
    }

    .lineac2 {
      border: 1px solid #7B808C;
      width: 100%;
      //margin: 10px 0;
    }

    .acbacround2 {
      &:before {
        display: none;
      }

      background: #E5EBF7;
      box-shadow: 0 0 0 0;

      .lineac2 {

      }

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #0166FF;
      }

      .actitle2 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #000714;
      }
    }

  }

  padding: 45px 0;

  .col-12 {
    padding: 0 0;
    //display: flex;
    .card {
      width: 100%;
      cursor: pointer;
      background-color: #E5EBF7;
      border: 1px solid #E5EBF7;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 0 10px;

      h1 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

      h6 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
      }
    }
  }

  .maqsadliAud {
    width: 100%;

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #0166FF;
    }
  }

  .cicrle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;

    span {
      position: absolute;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
    }
  }

  .cicrleText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }

}

.staff {
  padding: 45px 0;

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
  }

  .col-3 {
    .card {
      margin-top: 30px;
      border-radius: 32px 32px 0px 0px;

      .card-img-top {
        border-radius: 32px 32px 0px 0px;

        img {
          width: 262px;
          border-radius: 32px 32px 0px 0px;
        }
      }

      .card-body {
        background-color: #E5EBF7;
        //padding-bottom: 0px;
        height: 220px;

        h4 {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        p {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          color: #424752;
        }
      }
    }
  }
}

.modalOpen {
  position: absolute;
  transition: all 3s linear;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 85%;
  background-color: #E5E5E5;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
}

.xodimForm {
  position: absolute;
  width: 928px;
  height: 250px;
  background: white;
  z-index: 2;
  opacity: 100%;
  border-radius: 32px;
}

.xodimForm1 {
  background-color: white;
  //display: none;
  position: absolute;
  width: 998px;
  height: 278px;
  border-radius: 32px;
  margin-left: -50%;
  margin-top: 40%;
  transition: all 0.5s linear;

  .ModalImgOne {
    margin-left: 40px;
    margin-top: -40px;
    border-radius: 32px;
    width: 266px;
    height: 266px;
  }

  .modala {
    position: absolute;
    background: #0153a8;
    width: 100%;
    height: 100%;
  }

  .ModalImgTwo {
    position: absolute;
    margin-top: -30px;
    margin-left: 1000px;
    width: 20px;
    height: 20px;
  }

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }

  a {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #000714;
  }
}

.youthunion {
  padding: 45px 0;

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }
}

.laws {
  padding: 72px 0;

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #000714;
  }

  span {
    //margin-top: px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }

  p {
    margin-top: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000714;
  }

  .textLaws {
    //margin-bottom: 40px;
  }

  .lawsLink {
    cursor: pointer;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 266px;
    height: 48px;
    background: #0166FF;
    border-radius: 8px;
    text-align: center;
    margin-top: 40px;
  }

  p {

    text-decoration: none;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      color: white;
    }
  }

}

.lawsModal {
  position: relative;
  //height: 200px;
}

.lawsModalBody {
  .radioaf {
    label {
      margin-left: 10px;
    }
  }

  padding: 40px 60px;
  position: absolute;
  width: 1028px;
  height: 291px;
  background: #FFFFFF;
  border-radius: 32px;
  //background-color: red;
  margin-top: 25%;
  //bottom: 0;
  left: -52%;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #000714;
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424752;
  }

  .labelInput {
    margin-top: 30px;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #201918;
      margin-left: 10px;
    }
  }

  .yuklash {
    width: 240px;
    height: 48px;
    background: #0166FF;
    border-radius: 4px;
    padding: 10px 60px;
    text-decoration: none;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }

  .yuklash1 {
    margin-right: 20px;
    text-decoration: none;
    width: 240px;
    height: 48px;
    border-radius: 4px;
    padding: 10px 60px;
    border: 1px solid #201918;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #201918;
    }
  }
}


//Accardion

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}


.accounting {
  padding: 48px 0;

  .accountingName {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #000714;
  }

  .accountingSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 16px;
    margin-top: 16px;
    background: #E5EBF7;
    border: 1px solid #E5EBF7;
    box-sizing: border-box;
    border-radius: 8px;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #000714;
    }
  }

  .acbacround {
    background: #E5EBF7;
    padding: 10px 0;
    box-shadow: 0 0 0 0;
    border: 0;

    .lineac {
      border: 1px solid #7B808C;
      width: 100%;
      margin: 15px 0;
    }

    .lineac2 {
      border: 1px solid #7B808C;
      width: 100%;
      //margin: 10px 0;
    }
a{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  &:hover{
    text-decoration: none;

  }
}
    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #0166FF;
    }

    .acbacround2 {
      &:before {
        display: none;
      }

      background: #E5EBF7;
      box-shadow: 0 0 0 0;

      .lineac2 {

      }

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #0166FF;
      }

      .actitle2 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #000714;
      }
    }

  }

  .actitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000714;
  }
}


