// Tadbirkor Layout
.admin-layout {
  width: 100%;
  height: 100vh;
  display: flex;

  .admin-layout-left {
    border: 2px solid #E5EBF7;
    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    padding: 30px 0;
    padding-right: 10px;

    .user-info {
      img {
        width: 84px;
        height: 68px;
      }
    }

    .nav {
      margin-top: 25px;

      .nav-item {
        padding: 0 15px;

        .nav-link {
          span {
            margin-left: 10px;
          }

          display: flex;
          align-items: center;
          padding: 12px 15px;
          border-radius: 8px;
          //padding-left: 25px;
          //color: #888;
          position: relative;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #000714;

          &.active {
            background-color: #E5EBF7;
            color: #000;
            margin-left: 14px;
          }
        }
      }
    }
  }

  .admin-layout-right {
    width: calc(100% - 300px);
    height: 100%;
    overflow-y: auto;
    //background-color: #f2f4f8;
    //padding: 30px;
    position: relative;
    padding: 0 30px;

    .line {
      height: 2px;
      width: 100%;
      background-color: #E5EBF7;
      position: absolute;
      left: 0;
    }

    .adminrightTop {
      padding: 24px 0;
      //border: 2px solid #E5EBF7;
      //border-top-width: 0;
      //border-left-width: 0;
      //border-right-width: 0;
      h3 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000714;
      }

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #201918;
      }

      img {
        margin-right: 5px;
      }

      select {
        margin-right: 30px;
      }
    }
  }
}

.korxonaXaqida {
  padding-top: 30px;
  padding-bottom: 50px;

  .korxonaXaqidatop {
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      color: #000000;
    }
  }

  .korxonaDrop {
    .card {
      cursor: pointer;
    }

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      color: #000714;
    }

    h5 {

      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #424752;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000714;
    }
  }
}

.arizaYuborish {
  padding-top: 30px;

  .arizaYuborishtop {
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      color: #000714;

    }
  }
}

.meningArizam {
  padding-top: 30px;

  .meningArizamtop {
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      color: #000714;
    }
  }

  .meningArizambottom {
    position: relative;
    //padding: 20px 0;
    margin: 20px 0;

    .nextPage {
      position: absolute;
      background: #FFFFFF;
      //border: 1px solid #949494;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 40%;
      box-shadow: 0 0 5px 0 #949494;
    }

    .card {
      padding: 0 15px;
    }

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #201918;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #424752;
      padding-bottom: 8px;
    }

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #201918;
    }

    .linebottom {
      position: static;
      width: 90%;
      height: 0px;
      left: 0px;
      top: 92px;
      border: 1px solid #E5EBF7;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 16px 0px;
    }

    .arizaFayl {
      position: static;
      width: 286px;
      height: 48px;
      border: 1px solid #E9EAF0;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-top: 15px;

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #949494;
      }
    }
  }
}

.konXaqida {
  padding-top: 30px;
  padding-bottom: 60px;

  .konxaqidaTop {
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      color: #000714;
    }
  }

  .colLeft {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .spanNomer {
      position: absolute;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      color: #000714;
    }
  }

  .colRight {
    margin-left: 25px;

    p {
      padding-bottom: 10px;
      margin-bottom: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #424752;
    }

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000714;
    }
  }
}

.arizaYuborish {
  .arizaYuborishMenu {
    .arizaYuborishMenuLeft {
      span {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #424752;
      }

      .avfield {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #201918;
        background: #E5EBF7;

        &:focus {
          outline: none;
        }
      }
    }

    .arizaYuborishMeneRight {
      span {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #424752;
      }

      .arizaYuborishMeneRight1 {
        div {
          width: 39px;
          height: 39px;
          background: #E5EBF7;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #201918;
        }
      }
    }

    button {
      width: 104px;
      height: 40px;
      margin-top: 7px;
      background: #EB5757;
      border-radius: 8px;
      border: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      display: block;
      margin-left: auto;
    }

    .buttonPrimary {
      width: 104px;
      height: 40px;
      margin-top: 7px;
      background: #0166FF;
      border-radius: 8px;
      border: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      display: block;
      margin-left: auto;
    }
  }

  .buttonPrimary2 {
    width: 228px;
    height: 40px;
    background: #0166FF;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    display: block;
    margin-left: auto;
    border: 0;
    margin-bottom: 50px;
  }
}

.arizam {
  .ariz {
    //margin: 30px 0;
    margin-bottom: 50px;

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #424752;
      padding-bottom: 8px;
    }

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #201918;
    }

    .arizaFayl {
      position: static;
      width: 286px;
      height: 48px;
      border: 1px solid #E9EAF0;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-top: 15px;

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #949494;
      }
    }
  }

  .nextPage {
    background: #FFFFFF;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 #949494;
    margin-bottom: 15px;
  }

  .arizaYuborish {
    .arizaYuborishMenu {
      .arizaYuborishMenuLeft {
        span {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #424752;
        }

        .avfield {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #201918;
          background: #E5EBF7;

          &:focus {
            outline: none;
          }
        }
      }

      .arizaYuborishMeneRight {
        .colLeft {
          margin-top: 11%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 45px;
            height: 45px;
          }

          .spanNomer {
            position: absolute;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            color: #000714;
          }
        }

        span {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #424752;
        }

        .arizaYuborishMeneRight1 {
          div {
            width: 39px;
            height: 39px;
            background: #E5EBF7;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #201918;
          }
        }
      }

      button {
        width: 104px;
        height: 40px;
        margin-top: 7px;
        background: #EB5757;
        border-radius: 8px;
        border: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        display: block;
        margin-left: auto;
      }

      .buttonPrimary {
        width: 104px;
        height: 40px;
        margin-top: 7px;
        background: #0166FF;
        border-radius: 8px;
        border: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        display: block;
        margin-left: auto;
      }
    }

    .buttonPrimary2 {
      width: 228px;
      height: 40px;
      background: #0166FF;
      border-radius: 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      display: block;
      margin-left: auto;
      border: 0;
      margin-bottom: 50px;
    }
  }
}

.beshGr {
  padding: 30px 0;

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000714;
  }

  .bgr {
    border: 1px solid #E5EBF7;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 15px 15px;
    .span1{
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000714;
    }
    span{
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #424752;
    }
  }
  .createGr{
    margin-top: 20px;
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0;
    background: #0166FF;
    border-radius: 8px;
    span{
      img{
        margin-right: 15px;
      }
      display: flex;
      align-items: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin: 0 auto;
    }
  }
}
